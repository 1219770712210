import {
  BUTTON_CE_NAME,
  ButtonContentElementProps,
} from "./buttonContentElement";

/**
 * This is the global style of a CE.
 * This style is applied on all instances of this CE
 */
export default function ButtonContentElementStyleGlobal(
  props: ButtonContentElementProps
) {
  return (
    <style jsx global>
      {`
        :global(.${BUTTON_CE_NAME}) {
          display: flex;
        }
      `}
    </style>
  );
}
